import React from "react";
import { Link } from "react-router-dom";
import "../../assets/style/common/footer.scss";
import { BsDiscord } from "react-icons/bs";
import { AiFillLinkedin, AiFillTwitterCircle } from "react-icons/ai";
import { FaFacebook } from "react-icons/fa";
const Footer = () => {
  return (
    <>
      <footer className="app_footer">
        {/* <Container fluid> */}
        <div className="footer_text_wrap">
          <ul>
            <li>
              <Link target="_blank" to="https://t.me/RACEecosystem">
                Help center
              </Link>
            </li>
          </ul>
        </div>
        <div className="footer_icn_wrap">
          <ul>
            <li>
              <Link
                target="_blank"
                to="https://www.facebook.com/RACEecosystem/"
              >
                <FaFacebook />
              </Link>
            </li>
            <li>
              <Link target="_blank" to="https://x.com/RACEecosystem">
                <AiFillTwitterCircle />
              </Link>
            </li>
            <li>
              <Link
                target="_blank"
                to="https://www.linkedin.com/company/raceecosystem"
              >
                <AiFillLinkedin />
              </Link>
            </li>
          </ul>
        </div>
        {/* </Container> */}
      </footer>
    </>
  );
};

export default Footer;
